@import '../../../../assets/scss/mixins';

.slider_outer_container {
    max-width: 353px;
    position: relative;
    padding-top: 32px;
    margin: auto;
}
.swiper_slide {
    max-width: 353px;
    padding-bottom: 30px;
}
.btn {
    position: absolute;
    z-index: 10;
    right: 24px;
    bottom: 44px;
}
.single {
    max-width: 393px;
    padding-top: 32px;
}
.hidden {
    display: none;
}
